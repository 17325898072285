.loader {
  --rotation-animation-speed: 2s;
  --rotation-animation-easing: linear;
  --stroke-animation-speed: 1.5s;
  --stroke-animation-easing: ease-in-out;
  --stroke-width: 3px;
  --stroke-start-dasharray: 1, 200;
  --stroke-end-dasharray: 89, 200;
  margin: 0px auto;
  width: 40px;
  height: 40px;
  display: flex;
  max-width: fit-content;
}

.circularLoader {
  animation: rotate var(--rotation-animation-speed)
    var(--rotation-animation-easing) infinite;
}

.loaderPath {
  fill: none;
  stroke-width: var(--stroke-width);
  animation: animate-stroke var(--stroke-animation-speed)
    var(--stroke-animation-easing) infinite;
  stroke-linecap: round;
  stroke: #410cd9;
}

.loaderPathWhite {
  composes: loaderPath;
  stroke: #fff;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-stroke {
  0% {
    stroke-dasharray: var(--stroke-start-dasharray);
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: var(--stroke-end-dasharray);
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: var(--stroke-end-dasharray);
    stroke-dashoffset: -124;
  }
}
